import type { DefaultCacheLoader } from "../../../core/cache/default-cache-loader";
import { logger } from "../../../core/logging/logger";
import { Observable } from "../../../utils/observable";
import { ObservablePaginated } from "../../../utils/observable-paginated";
import { createEmptyPaginatedData } from "../../../utils/pagination";
import type { FeaturesManager } from "../../features/features-manager";
import type { UpcomingTransfer } from "./upcoming-transfers";
import type { UpcomingTransfersService } from "./upcoming-transfers-service";

export class UpcomingTransfersManager {
  public constructor(
    private upcomingTransfersService: UpcomingTransfersService,
    private featuresManager: FeaturesManager,
    private cacheLoader?: DefaultCacheLoader<UpcomingTransfer[]>,
  ) {}

  public paginatedUpcomingTransfers = new ObservablePaginated<UpcomingTransfer>(
    createEmptyPaginatedData<UpcomingTransfer>(),
  );

  public isLoading = new Observable<boolean>(false);
  public canLoadMore = new Observable<boolean>(false);

  public async getUpcomingTransfers(offset?: number) {
    const features = this.featuresManager.features.get();
    if (!features.paymentStandingOrderView && features.customerInstructionInitiation) {
      logger.debug("UpcomingTransfersManger", "User does not have permission to view upcoming transfers");
      this.paginatedUpcomingTransfers.set(createEmptyPaginatedData<UpcomingTransfer>());
      this.canLoadMore.set(false);
      return;
    }
    this.isLoading.set(true);
    try {
      const response = await this.upcomingTransfersService.getUpcomingTransfers(offset);
      if (offset) {
        this.paginatedUpcomingTransfers.add(response);
      } else {
        this.paginatedUpcomingTransfers.set(response);
      }
      this.canLoadMore.set(response.count > response.limit + Number(response.offset));
    } catch (error) {
      logger.debug("UpcomingTransfersManger", "Failed to get upcoming transfers", error);
      throw new Error(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  public async loadMore() {
    if (this.canLoadMore) {
      const paginatedUpcomingTransfers = this.paginatedUpcomingTransfers.get();
      const nextOffset = paginatedUpcomingTransfers.limit + Number(paginatedUpcomingTransfers.offset);
      await this.getUpcomingTransfers(nextOffset);
    }
  }
}
