import { logger } from "../../../core/logging/logger";
import type { ConnectedServiceDomainApiService } from "../../../core/net/connected-service-domain-api-service";
import type { Paginated } from "../../../utils/pagination";
import type { UpcomingTransfer } from "./upcoming-transfers";

export class UpcomingTransfersService {
  public constructor(private serviceDomainApiService: ConnectedServiceDomainApiService) {}

  public async getUpcomingTransfers(offset?: number): Promise<Paginated<UpcomingTransfer>> {
    try {
      const response = await this.serviceDomainApiService.instance.get("/standing-orders/credit-transfers", {
        params: {
          offset,
        },
      });
      return response.data;
    } catch (error) {
      logger.debug("UpcomingTransfersService", "Failed to get upcoming transfers", error);
      throw new Error(error);
    }
  }
}
