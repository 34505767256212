import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { upcomingTransfersManager } from "../../../../shared/core/service/services";
import useUpcomingTransfers from "../../../../shared/domains/transactions/upcoming-transfers/use-upcoming-transfers";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { PageHeader } from "../../common/page/page-header";
import { UpcomingTransferItem } from "./components/upcoming-transfer-item";

const UpcomingTransferScreen = () => {
  const { formatMessage } = useIntl();
  const { upcomingTransfers, canLoadMore, loadMore } = useUpcomingTransfers(upcomingTransfersManager);

  return (
    <>
      <PageHeader
        title={formatMessage("transfertsSubmenu.upcomingTransfers")}
        description={formatMessage("upcomingTransfers.description")}
      />
      <UpcomingTransfersWrapper>
        {upcomingTransfers.map((transfer) => (
          <UpcomingTransferItem key={transfer.id} transfer={transfer} />
        ))}
      </UpcomingTransfersWrapper>
      {canLoadMore && (
        <LoadMore>
          <PrimaryButton size="S" onClick={loadMore}>
            {formatMessage("upcomingTransfers.loadMore")}
          </PrimaryButton>
        </LoadMore>
      )}
    </>
  );
};

const UpcomingTransfersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoadMore = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

export default UpcomingTransferScreen;
